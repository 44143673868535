// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("jquery.easing")
require("jquery-hoverintent")
require("select2")
require("flatpickr")
require("./bootstrap_js_files")
require("slick-carousel")

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

var Alert = require("bootstrap/js/dist/alert").default

import LazyLoad from './vanilla-lazyload';
import Cookies from 'js-cookie';

const sorter = function(results) {
  let query = $('.select2-search__field').val().toLowerCase();
  return results.sort(function(a, b) {
    return a.text.toLowerCase().indexOf(query) -
      b.text.toLowerCase().indexOf(query);
  });
}

document.addEventListener("turbolinks:load", () => {
  //  for supermutt related stuff
  $('.modal-toggler').click(function() {
    $('#supermutt-content').toggleClass('d-none'); // step 1
  })

  // select2
  let query = {};
  $("[data-behavior='select2-nosearch']:not(.select2-hidden-accessible)").select2({
    selectionCssClass : 'select-field',
    language: {
      searching: function (params) {
        query = params;
        return 'Searching…';
      }
    },
    minimumResultsForSearch: Infinity,
    sorter: sorter,
  });
  $("[data-behavior='select2']:not(.select2-hidden-accessible)").select2({
    selectionCssClass : 'select-field',
    language: {
      searching: function (params) {
        query = params;
        return 'Searching…';
      }
    },
    sorter: sorter,
  });
  // select2 end

  // datepicker
  flatpickr("[data-behavior='flatpickr']:not(.flatpickr-input)", {
    altInput: true,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d',
    allowInput: true,
    onOpen: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', true);
    },
    onClose: function(selectedDates, dateStr, instance) {
      $(instance.altInput).prop('readonly', false);
      $(instance.altInput).blur();
    }
  });

  $('.email_subscription_form').on('ajax:success', function(el) {
    el.target.reset();
    $('body').append("<div class='alert alert-dismissible fade show' role='alert'>Welcome to the community! Thank you for subscribing!<button aria-label='Close' class='btn-close' data-bs-dismiss='alert' type='button'></button></div>")
  });

  if (Cookies.get('_tgp_showed_quiz_result') != null) {
    showResult();
  }

  $('div.future-feature').on('click', function() {
    alert('Coming soon!');
  });

  // amazon hack
  // $('html').on('click', function() {
  //   if (Cookies.get('_tgp_amazon') == null) {
  //     Cookies.set('_tgp_amazon', Date.now(), { domain: "." + document.domain, expires: 5 })
  //     window.open('https://amzn.to/2Zip51O')
  //   }
  // });

  $('#quiz_result_reveal_form').on('ajax:success', function(data) {
    showResult();
    const cookie = Cookies.get('_tgp_showed_quiz_result');
    if (cookie == null) {
      Cookies.set('_tgp_showed_quiz_result', Date.now(), { domain: "." + document.domain, expires: 30 })
    }
  });

  $('.no-thanks').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    showResult();
  })

  function showResult() {
    $('#quiz-result-container').removeClass('d-none')
    $('#quiz_result_reveal_form').addClass('d-none')
  }

  $('.option-button').on('click', function(e) {
    const radio = $('#' + e.target.dataset.radioId)
    const checked = radio.prop('checked')
    radio.prop('checked', !checked);
    $('.option-button').removeClass('active');
    $(e.target).addClass('active');
  });

  new LazyLoad({ elements_selector: '.lazy' });

  $('.slick').slick();
});
